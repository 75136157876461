import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import CaseStudyContentBody from "../../components/pages/CaseStudyDetails/CaseStudyContentBody"

const StreamliningFinancialManagementForSmallRetailBusiness = () => {
  return (
    <Layout>
      <Seo
        title="Streamlining Financial Management For Small Retail Business"
        description={`Explore our case study on streamlining financial management for small retail businesses. Learn how efficient solutions improved financial operations and boosted success.`}
      />

      {/* This slug is matched with blogs in Content */}
      <CaseStudyContentBody
        slug={`streamlining-financial-management-for-small-retail-business`}
      />
    </Layout>
  )
}

export default StreamliningFinancialManagementForSmallRetailBusiness
